<template>
    <v-container>
        <v-layout row wrap class="text-xs-center pt-0 pb-3">
            <v-flex xs12>
                <h1>
                    Wirkung von Massagen
                </h1>
            </v-flex>
            <v-flex xs12  class="text-xs-left pt-3 mt-3">
                <br>

                <p>Die theoretischen Grundlagen der einzelnen Massagearten unterscheiden sich stark.
                    Diese basieren auf unterschiedlichen Behandlungstheorien.
                    Die Wirkungsweisen auf den Körper sind verschieden. Die wichtigsten sind:</p>
                <ul>
                    <li>K&ouml;rperliches und seelisches Wohlbefinden</li>
                    <li>Erstellung einer k&ouml;rperlichen Balance</li>
                    <li>Lokale Steigerung der Durchblutung</li>
                    <li>Senkung von Blutdruck und Pulsfrequenz</li>
                    <li>Entspannung der Muskulatur</li>
                    <li>L&ouml;sen von Verklebungen und Narben</li>
                    <li>Verbesserte Wundheilung
                        <v-img :src="require('@/assets/stone_wirkung.jpg')" class="wirkungImg hidden-sm-and-down" /></li>
                    <li>Schmerzlinderung</li>
                    <li>Entschlackung des Gewebes</li>
                    <li>Einwirken auf innere Organe &uuml;ber Reflexb&ouml;gen</li>
                    <li>Psychische Entspannung</li>
                    <li>Anxiolytische Effekte</li>
                    <li>Reduktion von Stresshormonen (u.a. Cortisol)</li>
                    <li>Verbesserung des Zellstoffwechsels im Gewebe</li>
                    <li>Entspannung von Haut und Bindegewebe</li>
                    <li>Beeinflussung des vegetativen Nervensystems</li>
                </ul>

            </v-flex>
        </v-layout>
    </v-container>
</template>


<script>
    export default {
        name: "Wirkung",
        metaInfo () {
            return {
                content: 'Wirkung von Massagen: ' +
                    'Die theoretischen Grundlagen der einzelnen Massagearten unterscheiden sich stark. ' +
                    'Diese basieren auf unterschiedlichen Behandlungstheorien. ' +
                    'Die Wirkungsweisen auf den Körper sind verschieden.'
            }
        }
    }
</script>

<style scoped>

</style>