<template>
    <v-container class=" max-width">
        <v-layout row wrap class="text-xs-center pt-0 pb-3">
            <v-flex xs12>
                <h1>
                    Kontakt
                </h1>
            </v-flex>
            <v-flex xs6 offset-xs3 class="text-xs-center pt-3 mt-3">
                <br>
                <h3>Sandra Oechslin-Seiler</h3>
                <p>
                    Eisenbahnstrasse 17 <br>
                    8840 Einsiedeln <br>
                    055 422 28 80
                </p>
            </v-flex>
            <v-flex xs12 >
                <h2>
                    Anfahrt
                </h2>
            </v-flex>
            <v-flex xs12
                    v-if="Directions"
            >
                <ChicasaMap></ChicasaMap>
            </v-flex>
            <v-flex xs12 >
                <h2>
                    Anfrage
                </h2>
            </v-flex>
            <v-flex
                    xs12
                    grow-shrink-0
                    v-if="Form"
            >
                <KontaktForm></KontaktForm>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import KontaktForm from '../Components/KontaktFormular.vue';
    import ChicasaMap from '../Components/ChicasaMap.vue';

    export default {
        name: "Kontakt",
        components: {
            KontaktForm,
            ChicasaMap,
        },
        data () {
            return {
                Form: true,
                Directions: true,
            }
        },

    }
</script>

<style scoped>

</style>