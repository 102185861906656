<template>
    <v-container>
        <v-layout row wrap class="text-xs-center pt-0 pb-3">
            <v-flex xs12>
                <h1>
                    Fussreflexzonen Massage (FRZ)
                </h1>
            </v-flex>
            <v-flex xs12  class="text-xs-left pt-3 mt-3">
                <br>
                <p>
                    Die therapeutischen Möglichkeiten über Fussreflexzonen waren schon vor Jahrtausenden
                    in Asien und Indien, bei den Indianern und in Ägypten bekannt.
                    Der amerikanische Arzt Dr. William Henry Fitzgerald (1872–1942) hat als einer der Ersten das Wissen
                    um die Reflexzonen in seine Forschungen und Beobachtungen miteinbezogen
                    und erstmals in ein systematisches Konzept gestellt.
                    Die amerikanische Physiotherapeutin Eunice Ingham hat auf diesen Erfahrungen aufgebaut
                    und 1938 ihre erste Schrift „Geschichten, die die Füsse erzählen können” herausgegeben.
                    Weitere Anhänger verfeinerten diese Therapieform stetig.
                </p>
                <br>
                <p>Die Wirkungen der FRZ sind:</p>
                <ul>
                    <li>Anregung des Blutkreislaufes, der Organ- und Drüsenfunktionen</li>
                    <li>Anregung der Ausscheidung von Ablagerungen, Abfall- und Giftstoffen</li>
                    <li>Freisetzung von Energie im ganzen Körper</li>
                    <li>Anregung des Energieflusses</li>
                    <li>Herbeiführung von physischer und psychischer Entspannung</li>
                    <li>Mobilisierung der körpereigenen Kräfte</li>
                </ul>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "Fussreflexzonen"
    }
</script>

<style scoped>

</style>