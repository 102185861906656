<template>
    <v-container >
        <v-layout row wrap class="text-xs-center pt-0 pb-3">
            <v-flex xs12>
                <h1>
                    Ärztliche Verordnung
                </h1>
            </v-flex>
            <v-flex xs12 fill-height class="text-xs-left pt-3 mt-3 link"
                @click.stop="openFile('/backend/pdfs/Chicasa_Verordnung.pdf')"
            >

                <p>
                    Hier k&ouml;nnen Sie die Vorlage f&uuml;r die
                </p>
                <p>
                    &quot;&Auml;RZTLICHE VERORDNUNG ZUR MEDIZINISCHEN MASSAGE&quot;
                </p>
                <p>
                    herunterladen.
                    Nutzen sie diese Vorlage, um sie von Ihrem Arzt ausf&uuml;llen zu lassen.
                </p>
                <p>Um die PDF-Datei zu laden, klicken Sie hier.</p>

            </v-flex>
            <v-flex>
                <div class="headerSpacer"></div>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "Verordnung",
        methods: {
            openFile(filename){
                window.open( filename, '_blank');
            },
        }
    }
</script>

<style scoped>

</style>