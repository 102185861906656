<template>
    <v-container>
        <v-layout row wrap class="text-xs-center pt-0 pb-3">
            <v-flex xs12>
                <h1>
                    Rückenmassage
                </h1>
            </v-flex>
            <v-flex xs12 fill-height class="text-xs-left pt-3 mt-3 ">
                <br>
                <p>
                    Die Rücken- und Nackenmassage gehört zu den klassischen Körpermassagen und hat dieselben Eigenschaften.
                </p>
                <br>
                <p>
                    An Stelle des ganzen Körpers werden im Nacken- und Schulter-Bereich die Muskeln gelöst
                    und die Verspannungen beseitigt. Die Rücken- und Nackenmassage dauert 30 bis 45 Minuten.
                </p>


            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "Ruecken"
    }
</script>

<style scoped>

</style>