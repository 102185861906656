<template>
    <v-container>
        <v-layout row wrap class="text-xs-center pt-0 pb-3">
            <v-flex xs12>
                <h1>
                    Bindegewebsmassage (BGM)
                </h1>
            </v-flex>
            <v-flex xs12  class="text-xs-left pt-3 mt-3">
                <br>
                <p>
                    Dies ist eine von der Krankengymnastin Elisabeth Dicke 1929 ausgearbeitete Massagetechnik, die später von der Ärztin Hede Teirich-Leube weiter entwickelt wurde.
                </p>
                <br>
                <br>
                <p>
                    Die BGM baut auf der anatomischen Tatsache auf, dass von den Rückenmarksegmenten
                    aus einerseits bestimmte Zonen in der Haut, andererseits bestimmte innere Organe versorgt werden.
                    Veränderungen an diesen können reflektorisch zu tastbaren Haut- und Unterhautveränderungen führen
                    und von dort aus behandelt werden. Es können rheumatische Erkrankungen,
                    Kopfschmerzen, Gefässerkrankungen sowie alle inneren Organe behandelt werden.
                </p>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "Bindegewebe"
    }
</script>

<style scoped>

</style>