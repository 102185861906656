<template>
    <v-container>
        <v-layout row wrap class="text-xs-center pt-0 pb-3">
            <v-flex xs12 >
                <h1>
                    Zur Person
                </h1>
            </v-flex>
            <v-flex xs12 fill-height class="text-xs-left pt-3 mt-3 ">
                <br>
                <p>
                    Mein Name ist Sandra Oechslin-Seiler, Geboren 1959. Seit Herbst 1993 arbeite ich als selbständige Therapeutin an der Eisenbahnstrasse 17 in CH 8840 Einsiedeln.
                </p>
                <br>
                <p>
                    Ich bin verheiratet, habe vier erwachsene Kinder und zwei Grosskinder.
                </p>
                <br>
                <h3>
                    Meine Ausbildungen sind:
                </h3>
                <br>
                <table border="0" cellpadding="15" cellspacing="5">
                    <tbody>
                    <tr>
                        <td class="pr-3 pl-2">1978</td>
                        <td>Handelsdiplom an der Kantonsschule in 8808 Pfäffikon</td>
                    </tr>
                    <tr>
                        <td class="pr-3 pl-2">1996</td>
                        <td>Akupunktur Massage & Energetisch-Statische-Behandlung nach Radloff</td>
                    </tr>
                    <tr>
                        <td class="pr-3 pl-2">1998</td>
                        <td>Medizinische Masseurin SVBM</td>
                    </tr>
                    <tr>
                        <td class="pr-3 pl-2">1999</td>
                        <td>Medizinische Masseurin FA SRK</td>
                    </tr>
                    <tr>
                        <td class="pr-3 pl-2">2005</td>
                        <td>Beraterin Biochemie nach Dr. med. Sch&uuml;ssler</td>
                    </tr>
                    <tr>
                        <td class="pr-3 pl-2">2011</td>
                        <td>Medizinische Masseurin EFA</td>
                    </tr>
                    </tbody>
                </table>
                <br>
                <p>
                    Ich bin seit 1995 Mitglied des Schweizerischen Verbandes der Berufsmasseure (SVBM)
                    und der Naturärzte Vereinigung der Schweiz (NVS).
                </p>
                <p>
                    Ich bin anerkannt bei allen Krankenkassen mit der Zusatzversicherung "Komplementärmedizin"
                    und NVS A-Mitglied als medizinische Masseurin EFA, anerkannt von EMR, ASCA, SWICA & EGK.
                </p>
                <p>
                    Meine Abrechnungs-Nummer im Zahlstellen-Register ZSR ist E464061.
                </p>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "Zur Person"
    }
</script>

<style scoped>

</style>