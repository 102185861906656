<template>
    <div >

        <v-container class="max-width">
            <v-layout row wrap class="text-xs-center pa-0 ma-0">

                <v-flex>
                    <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 300px"
                    >
                        <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                        </gmap-info-window>
                        <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="toggleInfoWindow(m, index)"
                        />
                    </GmapMap>
                </v-flex>

            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "ChicasaMap",
        data () {
            return {
                center: {
                    lat:47.129047,
                    lng:8.748780
                },
                zoom: 16,
                infoWindowPos: null,
                infoWinOpen: false,
                currentMidx: null,
                infoOptions: {
                    content: '',
                    //optional: offset infowindow so it visually sits nicely on top of our marker
                    pixelOffset: {
                        width: 0,
                        height: -35
                    },
                },
                markers : [
                    {
                        position: {
                            lat:47.129047,
                            lng:8.748780
                        },
                        text: 'chicasa Massage Praxis',
                        infoText: '<div>' +
                            '<h2>chicasa Massage Praxis</h2>' +
                            '<p>' +
                            'Eisenbahnstrasse 17 <br>' +
                            '8840 Einsiedeln<br>' +
                            '<a href="https://goo.gl/maps/W9kghbE7eN4SHrZC6">In Goolge Maps öffnen</a>' +
                            '</p>'
                    }
                ]
            }
        },
        methods: {
            toggleInfoWindow(marker, idx) {
                this.infoWindowPos = marker.position;
                this.infoOptions.content = marker.infoText;
                //check if its the same marker that was selected if yes toggle
                if (this.currentMidx === idx) {
                    this.infoWinOpen = !this.infoWinOpen;
                }
                //if different marker set infowindow to open and reset current marker index
                else {
                    this.infoWinOpen = true;
                    this.currentMidx = idx;
                }
            }
        }
    }
</script>