<template>
    <v-container>
        <v-layout row wrap class="text-xs-center pt-0 pb-3">
            <v-flex xs12>
                <h1>
                    Sportmassage
                </h1>
            </v-flex>
            <v-flex xs12 fill-height class="text-xs-left pt-3 mt-3 ">
                <br>
                <p>
                    Je nach Sportart und je nach der Intensität der sportlichen Betätigung
                    braucht der Körper die individuelle, dazugehörende Massage.
                </p>
                <br>
                <p>
                    Die Muskeln werden auf die sportliche Leistung vorbereitet
                    und nach den Strapazen durch gezielte Massnahmen wieder beruhigt.
                    Verspannte Muskeln werden wieder gelöst. 
                </p>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "Sport"
    }
</script>

<style scoped>

</style>