<template>
    <v-container>
        <v-layout row wrap class="text-xs-center pt-0 pb-3">
            <v-flex xs12>
                <h1>
                    Klassische Massage
                </h1>
            </v-flex>
            <v-flex xs12 fill-height class="text-xs-left pt-3 mt-3 ">
                <br>
                <p>
                    Die klassische Massage beeinflusst die Spannung von Haut und Muskeln
                    und wirkt ebenfalls auf innere Organe und Organsysteme.
                    Sie optimiert verschiedene Leistungen im Körper.
                    Ebenfalls befreit sie die Muskeln vom sogenannten Hartspann.
                </p>
                <br>
                <p>
                    Strapazierte Muskeln werden gelockert.
                    Je nach Betätigungsbereich ist diese Massage den jeweiligen Bedürfnissen angepasst.
                    Die klassische Körpermassage verbessert die Durchblutung, regt die Regeneration der Zellen an
                    und stärkt das Immunsystem.
                    Die regelmässige Entspannung steigert das körperliche und seelische Wohlbefinden.
                </p>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "Klassisch"
    }
</script>

<style scoped>

</style>