<template>
    <v-container>
        <v-layout row wrap class="text-xs-center pt-0 pb-3">
            <v-flex xs12>
                <h1>
                    Akupunkturmassage (APM) und Energetisch-Statische-Behandlung (ESB)
                </h1>
            </v-flex>
            <v-flex xs12  class="text-xs-left pt-3 mt-3">
                <br>
                <p>
                    Die APM / ESP ist eine Form der Meridiantherapie. Sie basiert darauf,
                    dass Krankheit eine Störung des Energieflusses darstellt
                    und mittels einer Spannungs-Ausgleichsmassage der Energieausgleich wieder hergestellt werden kann.
                </p>
                <br>
                <p>
                    Eine Meridianmassage wird mit einem aus Metall bestehenden Massagestäbchen durchgeführt.
                    Die APM befolgt die Regeln und Gesetze der traditionellen chinesischen Akupunktur.
                    Die Pioniere dieser Technik sind Willi Penzel, ab etwa 1950 für APM, und später Klaus Radloff, für ESB.
                </p>
                <br>
                <p>
                    Ein zusätzlicher und wichtiger Bestandteil nach APM / ESB nach Radloff
                    ist die positive Wirkung auf die Statik, besonders auf die Wirbelsäule, sowie auf die Körpergelenke.
                </p>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "Akkupunktur",

    }
</script>

<style scoped>

</style>