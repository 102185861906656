<template>
    <v-container>
        <v-layout row wrap class="text-xs-center pt-0 pb-3">
            <v-flex xs12>
                <h1>
                    Impressum
                </h1>
            </v-flex>
            <v-flex xs12 fill-height class="text-xs-left pt-3 mt-3 ">

                Eisenbahnstrasse 17<br />
                CH-8840 Einsiedeln
                Telefon: &nbsp;+41 55 422 28 80<br />
                E-Mail: &nbsp; &nbsp;info@chicasa.ch
                <br />
                <br />
                <br />
                F&uuml;r inhaltliche Fehler wird keine Gew&auml;hrleistung &uuml;bernommen.<br />
                <br />

                <hr style="width: 100%; height: 2px;" />
                <br>
                <br>
                <h2 class = text-xs-center >Rechtlicher Hinweis</h2>
                <br>
                <br>

                    <p>Weder der Betreiber noch der Inhaber dieser Website haften f&uuml;r den Inhalt der Webseiten,
                        zu welchen verwiesen wird. Alle Angebote auf den Seiten von www.chicasa.ch sind freibleibend
                        und bed&uuml;rfen einer Best&auml;tigung.
                        Etwaige Anspr&uuml;che k&ouml;nnen daraus nicht hergeleitet werden.</p>

                Copyright Chicasa.ch. Alle Rechte vorbehalten.
                Alle Informationen dienen zur pers&ouml;nlichen Information - eine kommerzielle Nutzung der redaktionellen Beschreibungen / der Strukturierung ist nicht erlaubt.
                Die auf Chicasa.ch ver&ouml;ffentlichten Texte und Bilder sind urheberrechtlich gesch&uuml;tzt. F&uuml;r
                Fehler im Text oder falsche Links wird keine Haftung &uuml;bernommen.
                F&uuml;r die Inhalte der gefundenen Angebote sind die jeweiligen Betreiber verantwortlich.
                Ausserdem ist das automatische Auslesen von Daten durch Software untersagt.



            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "Impressum"
    }
</script>

<style scoped>

</style>