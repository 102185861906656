<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-layout row wrap>
                <v-flex xs12 grow-shrink-0 class="pt-5">
                    <v-alert
                            :value="dataSaved"
                            type="success"
                            transition="scale-transition"
                            outline
                    >
                        Die Anfrage wurde gesendet.
                    </v-alert>
                    <v-alert
                            :value="dataNotSaved"
                            type="error"
                            transition="scale-transition"
                            outline
                    >
                        Die Anfrage konnte nicht gesendet werden.
                    </v-alert>
                    <v-form ref="form" v-model="valid" lazy-validation class="form">
                        <div>
                            <v-text-field
                                    v-model="surName"
                                    :rules="surNameRules"
                                    label="Nachname"
                            ></v-text-field>
                            <v-text-field
                                    v-model="preName"
                                    :rules="preNameRules"
                                    label="Vorname"
                            ></v-text-field>
                            <v-text-field
                                    v-model="eMail"
                                    :rules="eMailRules"
                                    label="Ihre Email-Adresse"
                            ></v-text-field>
                            <v-textarea
                                    v-model="userRequest"
                                    :rules="userRequestRules"
                                    label="Anfrage"
                            ></v-textarea>
                            <v-text-field
                                    v-model="phoneNumber"
                                    :rules="phoneNumberRules"
                                    label="Ihre Telefonnummer"
                            ></v-text-field>
                        </div>
                        <VueRecaptcha class="pb-4 pt-1" sitekey="6LfuXrIUAAAAALDppU5MWmtd2TfQNOrEpf1iaSRX"
                                      @verify="verifyHuman"
                        >
                        </VueRecaptcha>
                        <v-btn
                                :disabled="!valid"
                                @click="submit"
                        >
                            Absenden
                        </v-btn>
                        <v-btn @click="clear">
                            Zurücksetzen
                        </v-btn>
                    </v-form>
                </v-flex>
            </v-layout>
        </v-slide-y-transition>
    </v-container>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha';
    export default {
        name: "KontaktFormular",
        components: {VueRecaptcha},
        data () {
            return {
                valid: false,
                surName: '',
                surNameRules: [
                    v => !!v || 'dieses Feld ist erforderlich',
                    v => /^$|^[\u00C0-\u017Fa-zA-Z']+$|^[\u00C0-\u017Fa-zA-Z']+\s[\u00C0-\u017Fa-zA-Z']+$/.test(v) || 'der Nachname darf aus Maximal 2 Wörtern bestehen'
                ],
                preName: '',
                preNameRules: [
                    v => !!v || 'dieses Feld ist erforderlich',
                    v => /^$|^[\u00C0-\u017Fa-zA-Z']+$|^[\u00C0-\u017Fa-zA-Z']+\s[\u00C0-\u017Fa-zA-Z']+$|^[\u00C0-\u017Fa-zA-Z']+\s[\u00C0-\u017Fa-zA-Z']+\s[\u00C0-\u017Fa-zA-Z']+$/.test(v) || 'der Vorname darf aus Maximal 3 Wörtern bestehen'
                ],
                userRequest: '',
                userRequestRules: [
                    v => !!v || 'dieses Feld ist erforderlich',
                ],

                phoneNumber: '',
                phoneNumberRules: [
                    v => !!v || 'dieses Feld erforderlich',
                    v => /^\d+$|^\d+\s+\d+$|^\d+\s+\d+\s+\d+$|^\d+\s+\d+\s+\d+\s+\d+$|^\d+\s+\d+\s+\d+\s+\d+\s+\d+$|^\+\d+$|^\+\d+\s+\d+$|^\+\d+\s+\d+\s+\d+$|^\+\d+\s+\d+\s+\d+\s+\d+$|^\+\d+\s+\d+\s+\d+\s+\d+\s+\d+$/.test(v) ||'Bitte benutzen Sie ein gängiges Format. Beispiele: +41 79 999 99 99, 079 999 99 99, 0799991199 etc.'
                ],
                eMail: '',
                eMailRules: [
                    v => !!v|| 'dieses Feld erforderlich',
                    v => /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(v) || 'Ungültige email adresse',
                ],
                gRecaptchaKey: '',
                clearFollowMe: false,
                dataSaved: false,
                dataNotSaved: false,
                responseCode: '',
            }
        },
        mounted() {
            let recaptchaScript = document.createElement('script');
            recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit');
            document.head.appendChild(recaptchaScript);
        },
        methods: {
            submit() {
                if (this.$refs.form.validate()) {
                    let Url = window.origin + '/backend/KontaktFormular.php';

                    let reqData;

                    let baseReqData;

                    baseReqData = {
                        captchaString: this.gRecaptchaKey,
                        surName: this.surName,
                        preName: this.preName,
                        userRequest: this.userRequest,
                        phoneNumber: this.phoneNumber,
                        eMail: this.eMail,
                    };

                    reqData = JSON.stringify(baseReqData);

                    let Params = {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                        },
                        method: "POST",
                        body: reqData
                    };

                    postData(Url, Params)
                        .then(responseJson => {
                            if (responseJson['success'] === true) {
                                this.changeDataSaved(true);
                                setTimeout(() => this.changeDataSaved(false), 5000);
                                this.clear();

                            } else {
                                this.changeDataNotSaved(true);
                            }
                        } )
                        .catch(responseJson =>{

                            if (responseJson['error'] === true){
                                this.changeDataNotSaved(true);
                                setTimeout(() => this.changeDataNotSaved(false), 5000);
                            }
                        });




                }
            },
            verifyHuman(response) {
                this.gRecaptchaKey = response;
            },
            clear () {
                this.$refs.form.reset()
            },
            changeDataSaved(value) {
                this.dataSaved = value;
            },
            changeDataNotSaved(value) {
                this.dataNotSaved = value;
            }
        },
        computed: {
            responseCodeComputed() {
                return this.responseCode
            }
        }

    }

    function postData(Url, Params) {

        return fetch( Url, Params)
            .then( response => response.json())
            .catch( response => response.json())

    }
</script>

<style scoped>

</style>