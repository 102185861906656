<template>
    <v-container>
        <v-layout row wrap class="text-xs-center pt-0 pb-3">
            <v-flex xs12>
                <h1>
                    Lymphdrainage (MLD)
                </h1>
            </v-flex>
            <v-flex xs12 fill-height class="text-xs-left pt-3 mt-3 ">
                <br>
                <p>
                    Der Körper besitzt eine eigene Kläranlage, das Lymphsystem. Überflüssiges Gewebematerial,
                    alte Zelltrümmer und Schlackenstoffe werden damit abtransportiert.
                </p>
                <br>
                <p>
                    Bei Störungen kommt es zu Schwellungen in den Extremitäten, zu Hautunreinheiten im Gesicht,
                    zu Tränensäcken und weiteren ähnlichen Beschwerden. Die manuelle Lymphdrainage erlaubt es mit
                    sanften Pump-, Schiebe- und Drehbewegungen den Lymphfluss zu steigern. Dadurch kann das Lymphsystem,
                    das auch Immunfunktionen wahrnimmt, seine Tätigkeit besser erfüllen. 
                </p>
                <br>
                <p>
                    Entdeckt hat der dänische Physiotherapeut und Philosoph Dr. Emil Vodder diese Therapieform um 1930.
                    Die manuelle Lymphdrainage ist eine Therapieform der physikalischen Anwendung.
                </p>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "Lymphdrainage"
    }
</script>

<style scoped>

</style>