<template>
    <div >
        <v-container>
            <v-layout row wrap class="text-xs-center pt-0 pb-3">
                <v-flex xs12>
                    <h1>
                        Geschenkgutscheine
                    </h1>
                </v-flex >
                <v-flex xs12 class="text-xs-left pt-3 mt-3 ">
                    <br>
                    <p>
                        Ich biete ihnen an, meine Angebote an Freunde zu verschenken.
                        Etwa ein Gutschein für eine bestimmte Massage? Und damit Ruhe und Entspannung?
                        Eine Auszeit aus der hektischen Umwelt?
                        Die Massage kann an die Bedürfnisse des Beschenkten und an seine Verfassung angepasst werden.
                    </p>
                    <v-img height="150px" contain :src="require('@/assets/stone_angebote.jpg')" />
                    <p>
                        Nur ein paar
                        Informationen und Clicks, und Sie
                        haben das ideale Geschenk. <br />
                    </p>
                    <h2 class="text-xs-center pa-3">
                        Es stehen folgende Gutscheine zur Auswahl:
                    </h2>
                    <table cellspacing="10" class="centered" >
                        <tbody>
                            <tr>
                                <td>60 Min.</td>
                                <td colspan="2">Massage nach Wahl</td>
                                <td style="text-align: right; ">149.00</td>
                            </tr>
                            <tr>
                                <td>45 Min.</td>
                                <td colspan="2">Massage nach Wahl</td>
                                <td style="text-align: right; ">113.00</td>
                            </tr>
                            <tr>
                                <td>30 Min.</td>
                                <td colspan="2">Massage nach Wahl</td>
                                <td style="text-align: right; ">77.00</td>
                            </tr>
                        </tbody>
                    </table>

                </v-flex>
                <v-flex xs12 grow-shrink-0>
                    <OrderForm></OrderForm>
                </v-flex>
            </v-layout>
        </v-container>

    </div>
</template>

<script>

    import OrderForm from '../Components/OrderForm.vue';
    export default {
        name: "Angebote",
        components: {
            OrderForm,
        },
        metaInfo () {
            return {
                content: 'Geschenkgutscheine: ' +
                    'Nur ein paar Informationen und Clicks, und Sie haben das ideale Geschenk. '
            }
        }
    }
</script>

<style scoped>

</style>